import { List, ListItem, Form, Col } from "@deere/ux.uxframe-react";
import { DIRECT_STRATEGY_MANAGEMENT } from "../shared/AppConstants";

export default function DMSMMGC(props) {
  const { strategyInfo } = props;
  let mgcs = strategyInfo?.decorated?.mgcs;
  return (
    <Form.Group as={Col} xs={12} md={10}>
      <Form.Label className="uxf-label">Material Group Code (MGC)</Form.Label>
      {mgcs && mgcs.sort() && (
        <div
          style={{
            overflowY: "auto",
            maxHeight: "150px",
            width: "200px",
          }}
        >
          <List className="pl-0" variant="uxf-ul">
            {mgcs.map((elem) => (
              <ListItem className="pl-3" key={elem.split(" - ")[0]}>
                {elem.split(" - ")[0]}
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </Form.Group>
  );
}
