import {
  CURRENT,
  DIRECT_STRATEGY_MANAGEMENT,
  FORM_TYPE_ID_IN_STRATEGY,
  FORM_TYPE_ID_NOT_IN_STRATEGY,
  INDIRECT_STRATEGY_MANAGEMENT,
} from "./AppConstants";
import {fetchJsonWithAuth} from "./fetch-json.util";
import { getFormattedUserNameWithId } from "./UserLogin";

function getSDI(sdis) {
  let array = [];

  sdis?.forEach((sdi) => {
    let sdiObject = {
      sdi: sdi.label,
    };
    array.push(sdiObject);
  });

  return array;
}
function getFormattedVettedSuppliers(items) {
  let array = [];

  items?.forEach((item) => {
    let vettedSupplier = {
      vettedProspectiveSupplierSDIS: getSDI(item.sdis),
      supplierType: item.supplierType,
      nameOrNumber: item.suppliers.value
        ? item.suppliers.value
        : item.suppliers,
      prospectingTool: item.vettedProspectingTool,
      reasonCode: item.vettedReasonCode,
      comments: item.vettedSupplierComment,
    };
    array.push(vettedSupplier);
  });

  return array;
}

export function putOrPostStrategy(data, sdfId, strategyNumber, appName) {
  let url;
  let method;
  let body;

  let commonPayLoad = {
    nonStrategyFormPONumber: data.POorSANumber ? data.POorSANumber : null,
    nonStrategyFormUnitCode: data.unit ? data.unit.value : null,
    sdsConsidered: data.sdsConsidered === "Yes",
    sdsNotConsideredReasonCode: data.reasonCode,
    sdsConsideredComments: data.comment,
    strategyId: strategyNumber ? strategyNumber : null,
    appName: strategyNumber ? appName : null,
    formType: strategyNumber
      ? FORM_TYPE_ID_IN_STRATEGY
      : FORM_TYPE_ID_NOT_IN_STRATEGY,
    vettedSuppliers:
      data.sdsConsidered === "Yes"
        ? getFormattedVettedSuppliers(data.items)
        : null,
  };

  if (sdfId && sdfId !== 0) {
    url = process.env.REACT_APP_SDF_API_URL + "/" + sdfId;
    method = "PUT";
    body = {
      ...commonPayLoad,
      number: sdfId,
    };
  } else {
    url = process.env.REACT_APP_SDF_API_URL;
    method = "POST";
    body = {
      ...commonPayLoad,
    };
  }

  return { url: url, method: method, body: body };
}

export function callUserInfoAPI(
  userIds,
  setterMapFn,
  setterListFn,
  incrementAPIInProgress,
  decrementAPIInProgress,
  setStatusErrorMsg
) {
  let api = process.env.REACT_APP_USERSEARCH_API_URL;

  if (userIds && userIds.length > 0) {
    incrementAPIInProgress();
    fetchJsonWithAuth(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(userIds),
    })
      .then((response) => {
        if (setterMapFn) {
          let map = new Map();

          response.json.forEach((user) => map.set(user.id.toUpperCase(), user));
          setterMapFn(map);
        }
        setterListFn && setterListFn(response.json);
      })
      .catch((error) =>
        setStatusErrorMsg(
          "Couldn't retrieve User Info. Please try again.",
          error
        )
      )
      .finally(() => decrementAPIInProgress());
  }
}

export function callOwnerInfoAPI(
  ownerId,
  setterFn,
  incrementAPIInProgress,
  decrementAPIInProgress,
  setStatusErrorMsg
) {
  incrementAPIInProgress();
  fetchJsonWithAuth(
    process.env.REACT_APP_USERSEARCH_API_URL +
      "/employees?searchCriteria=" +
      escape(ownerId.toUpperCase())
  )
    .then((response) => {
      response.json &&
        response.json.length > 0 &&
        setterFn(getFormattedUserNameWithId(response.json[0], ownerId));
    })
    .catch((error) =>
      setStatusErrorMsg(
        "Couldn't load the Owner Info. Please try again.",
        error
      )
    )
    .finally(() => decrementAPIInProgress());
}

function loadSupplier(suppliersNameArray, setSuppliersMap, setStatusErrorMsg) {
  let supplier;

  fetchJsonWithAuth(process.env.REACT_APP_SUPPLIERS_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({ supplierNumbers: suppliersNameArray }),
  })
    .then(async(res) => {
      const result = res.json;
      let map = new Map();
      const sdiMap = await getSdiMap(suppliersNameArray);

      result.forEach((item) => {
        supplier = {
          value: item.supplierNumber,
          label: item.supplierNumber + " - " + item.supplierName,
          sdis: sdiMap.get(item.supplierNumber),
        };
        map.set(item.supplierNumber, supplier);
      });
      setSuppliersMap(map);
    })
    .catch((error) => {
      setStatusErrorMsg("Couldn't fetch Suppliers.", error);
    });
}

export function callSdfAPI(
  sdfNumber,
  setSdfUser,
  setSdfInfo,
  setSuppliersMap,
  incrementAPIInProgress,
  decrementAPIInProgress,
  setStatusErrorMsg
) {
  let url = process.env.REACT_APP_SDF_API_URL;

  incrementAPIInProgress();

  fetchJsonWithAuth(url + "/" + sdfNumber)
    .then((response) => {
      const data = response.json;

      if (response.meta.status === 404)
        setStatusErrorMsg("Couldn't find the SDF for Id - " + sdfNumber);
      else {
        let suppliersNameArray = [];
        data?.vettedSuppliers?.forEach((item) => {
          if (item.supplierType === CURRENT) {
            suppliersNameArray.push(item.nameOrNumber);
          }
        });
        suppliersNameArray.length > 0 &&
          loadSupplier(suppliersNameArray, setSuppliersMap, setStatusErrorMsg);
        callOwnerInfoAPI(
          data.updatedById,
          setSdfUser,
          incrementAPIInProgress,
          decrementAPIInProgress,
          setStatusErrorMsg
        );
        setSdfInfo(data);
      }
    })
    .catch((error) =>
      setStatusErrorMsg("Couldn't load the SDF. Please try again.", error)
    )
    .finally(() => decrementAPIInProgress());
}

export function callStrategiesAPI(
  applicationName,
  strategyNumber,
  sdfNumber,
  setStrategyInfo,
  setStrategicSuppliers,
  incrementAPIInProgress,
  decrementAPIInProgress,
  setStatusErrorMsg
) {
  let url =
    applicationName === INDIRECT_STRATEGY_MANAGEMENT
      ? process.env.REACT_APP_SM_STRATEGIES_API_URL
      : process.env.REACT_APP_DMSM_STRATEGIES_API_URL;
  let urlParams =
    applicationName === INDIRECT_STRATEGY_MANAGEMENT
      ? "embed=no_spend"
      : "embed=decorate";

  incrementAPIInProgress();

  fetchJsonWithAuth(url + "/" + strategyNumber + "?" + urlParams)
    .then((response) => {
      if (response.meta.status === 404)
        setStatusErrorMsg(
          "Couldn't find the strategy for Id - " + strategyNumber
        );
      else {
        if (response.json.sdsFormId && sdfNumber !== response.json.sdsFormId)
          setStatusErrorMsg(
            "Form already exists for strategy Id - " + strategyNumber
          );
        setStrategyInfo(response.json);
        if (setStrategicSuppliers)
          if (
            applicationName === INDIRECT_STRATEGY_MANAGEMENT ||
            applicationName === DIRECT_STRATEGY_MANAGEMENT
          ) {
            let supplierList = response.json.suppliers.map(
              (item) => item.supplierNumber
            );
            setStrategicSuppliers(supplierList);
          } else {
            let prefSupplier = response.json.suppliers
              .filter((item) => item.statusCode !== "")
              .map((item) => item.supplierNumber);

            setStrategicSuppliers(prefSupplier);
          }
      }
    })
    .catch((error) =>
      setStatusErrorMsg(
        "Couldn't load the strategy info. Please try again.",
        error
      )
    )
    .finally(() => decrementAPIInProgress());
}

export function windowClose(history) {
  if (window.opener) window.close();
  else history.push("/");
}

export async function getSdiMap(supplierNumbers) {
  if (!Array.isArray(supplierNumbers)) {
    supplierNumbers = [supplierNumbers]
  }
  let api = process.env.REACT_APP_SDI_CERT_API_URL + '/certs/sdis';

  const response = await fetchJsonWithAuth(api, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      supplierNumbers: supplierNumbers,
    })
  });
  return new Map(Object.entries(response.json));
}

export async function getMGCDescription(mgcCode) {
  try{
    let api = process.env.REACT_APP_VALIDATE_MGC;
    const response = await fetchJsonWithAuth(api, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify([mgcCode])
    })
    const mgcCodeDescriptionMap = new Map();
    const map = new Map(Object.entries(response.json));

    map.forEach((val, key) => {
      const commodityDesc = val.commodityDesc;

      if (commodityDesc !== "404 Not Found!") {
        const description = [
          commodityDesc,
          val?.majorDesc,
          val?.minorDesc,
          val?.segmentDesc
        ]
            .filter(Boolean)
            .join(" ")
        mgcCodeDescriptionMap.set(key.toUpperCase(), description)
      }
    })
    return mgcCodeDescriptionMap;
  } catch(err){
   console.log("ERROR: API - MGC Description Details ", err.message())
   throw err;
 }
}
