import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { Form, Col, Row, Button } from "@deere/ux.uxframe-react";
import {
  putOrPostStrategy,
  callOwnerInfoAPI,
  callSdfAPI,
  callStrategiesAPI,
  windowClose,
} from "../shared/SDFHelper";

import SMModal from "../shared/SMModal";
import usePrompt from "../shared/CustomPrompt";
import NoAccessEmployee from "./NoAccessEmployee";
import { useAPIProgress } from "../shared/APIProgress";
import { useStatusMessages } from "../shared/StatusMessages";
import { fetchJsonWithAuth } from "../shared/fetch-json.util";
import { useUserInfo } from "../shared/UserInfo";
import MessageBanner from "../shared/MessageBanner";
import Suppliers from "./Suppliers";
import GetProspectingAndReasonCodes from "./GetProspectingAndReasonCodes";
import GetSDIMasterList from "./GetSDIMasterList";
import SDSConsidered from "./SDSConsidered";
import {
  DIRECT_STRATEGY_MANAGEMENT,
  FORM_TYPE_IN_STRATEGY,
  INDIRECT_STRATEGY_MANAGEMENT,
  NO,
  YES,
  SMALL_BUSINESS_FORM,
  LABELS, MESSAGES
} from "../shared/AppConstants";

import { isFormNotGoodForSave } from "../shared/UtilFunctions";
import SMIndirectStrategy from "./SMIndirectStrategy";
import DMSMStrategy from "./DMSMStrategy";

export default function CreateSDForm() {
  let dateFormat = require("dateformat");
  let formatString = "dd-mmm-yyyy";
  const info = useUserInfo();
  const tokenAvailable = info && info.token;
  const loggedInUser = info && info.userId;
  const [strategyOwnerInfo, setStrategyOwnerInfo] = useState(null);
  const [sdfUser, setSdfUser] = useState(null);
  const [strategyInfo, setStrategyInfo] = useState(null);
  const [sdfInfo, setSdfInfo] = useState(null);
  const [sdiSuppliers, setSdiSuppliers] = useState();
  const [sdiMasterList, setSdiMasterList] = useState(null);
  const [prospectingTool, setProspectingTool] = useState(null);
  const [reasonCodes, setReasonCodes] = useState(null);
  const [hasAccess, setHasAccess] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { strategyId, sdfId, appName } = useParams();
  const [suppliersMap, setSuppliersMap] = useState(null);
  const [sdsConsideredForEdit, setSdsConsideredForEdit] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [applicationName] = useState(
      appName ? appName : DIRECT_STRATEGY_MANAGEMENT,
  );
  const [strategicSuppliers, setStrategicSuppliers] = useState(null);

  const [strategyNumber] = useState(strategyId ? strategyId : 0);
  const history = useHistory();
  const [sdfNumber, setSdfNumber] = useState(sdfId ? parseInt(sdfId) : 0);

  const [formType] = useState(strategyId ? FORM_TYPE_IN_STRATEGY : "");

  function isFormTouched() {
    return isDirty || isFormDirty;
  }

  // Read the formState before render to subscribe the form state through Proxy
  const [saveOrSubmitButtonText, setSaveOrSubmitButtonText] = useState(
      sdfNumber ? "Save" : "Submit"
  );
  const [headerText, setHeaderText] = useState(
      sdfNumber
          ? `${SMALL_BUSINESS_FORM} #` + sdfNumber
          : `${LABELS.CREATE_SMALL_BUSINESS_FORM}`
  );

  const { apiInProgressCount, incrementAPIInProgress, decrementAPIInProgress } =
      useAPIProgress();

  const {
    register,
    unregister,
    getValues,
    reset,
    control,
    handleSubmit,
    setError,
    errors,
    setValue,
    formState,
    clearErrors,
    watch,
  } = useForm({
    mode: "all",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });
  const watchFields = watch();

  const { isDirty } = formState;

  const {
    statusMessages,
    clearAllMessages,
    setStatusSuccessMsg,
    setStatusErrorMsg,
    setStatusWarningMsg,
  } = useStatusMessages();

  const commonProps = {
    tokenAvailable: tokenAvailable,
    register: register,
    unregister: unregister,
    getValues: getValues,
    incrementAPIInProgress: incrementAPIInProgress,
    decrementAPIInProgress: decrementAPIInProgress,
    setStatusErrorMsg: setStatusErrorMsg,
    setStatusSuccessMsg: setStatusSuccessMsg,
    control: control,
    setValue: setValue,
    errors: errors,
    reset: reset,
    clearErrors: clearErrors,
    watch: watch,
    setError: setError,
    fields: fields,
    append: append,
    remove: remove,
  };

  function loadStrategyOwnerInfo(strategyInfo) {
    strategyInfo?.ownerId &&
    callOwnerInfoAPI(
        strategyInfo?.ownerId,
        setStrategyOwnerInfo,
        incrementAPIInProgress,
        decrementAPIInProgress,
        setStatusErrorMsg
    );
  }

  function save(data) {
    clearAllMessages();

    let putOrPost = putOrPostStrategy(
        data,
        sdfNumber,
        strategyNumber,
        applicationName
    );

    incrementAPIInProgress();

    fetchJsonWithAuth(putOrPost.url, {
      method: putOrPost.method,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(putOrPost.body),
    })
        .then((response) => {
          const result = response.json;
          const { formId } = result;

          if (result.hasOwnProperty("400")) setStatusErrorMsg(result["400"]);
          else if (result.hasOwnProperty("404")) setStatusErrorMsg(result["404"]);
          else if (formId) {
            let message1;
            let message2 =`${MESSAGES.RETURN_TO_STRATEGY}}`;

            if (putOrPost.method === "POST") {
              message1 = `${MESSAGES.SUCCESSFULLY_CREATED}`;
              setSaveOrSubmitButtonText("Save");
              setHeaderText(`${SMALL_BUSINESS_FORM} #` + result.formId);
              setSdfInfo(result);
              setSdfNumber(result.formId);

              // Update SDF # in Strategy Info object
              let newStrategyInfo = { ...strategyInfo };

              newStrategyInfo.sdsFormId = result.formId;
              setStrategyInfo(newStrategyInfo);

              callOwnerInfoAPI(
                  result.updatedById,
                  setSdfUser,
                  incrementAPIInProgress,
                  decrementAPIInProgress,
                  setStatusErrorMsg
              );
            } else {
              message1 = `${MESSAGES.SUCCESSFULLY_UPDATED}`;
              callSdfAPI(
                  sdfNumber,
                  setSdfUser,
                  setSdfInfo,
                  setSuppliersMap,
                  incrementAPIInProgress,
                  decrementAPIInProgress,
                  setStatusErrorMsg
              );
            }
            // Call Parent with SDF #
            if (window.opener) {
              let url = process.env.REACT_APP_DMSM_BASE_URL;

              if (applicationName === INDIRECT_STRATEGY_MANAGEMENT) {
                url = process.env.REACT_APP_SM_BASE_URL;
              }
              window.opener.postMessage(result.formId, url);
            }

            setStatusSuccessMsg(message1, message2);
          }
        })
        .catch((error) =>
            setStatusErrorMsg(MESSAGES.SBF_SAVE_ERROR, error)
        )
        .finally(() => decrementAPIInProgress());
  }

  function validateAndSetAccess() {
    let hasAccess = false;

    if (
        loggedInUser.toUpperCase() ===
        strategyInfo.ownerId.toUpperCase()
    ) {
      hasAccess = true;

      if (sdfNumber > 0 && sdfNumber !== strategyInfo.sdsFormId)
        hasAccess = false;
    }
    setHasAccess(hasAccess ? YES : NO);
  }

  function cancelStrategy() {
    if (isDirty || isFormDirty) {
      setShowModal(true);
    } else windowClose(history);
  }

  useEffect(() => {
    let data = getValues();
    let isDisabled = true;

    if (isDirty) {
      isDisabled = isFormNotGoodForSave(data, sdiSuppliers);
    } else if (sdfNumber === 0 && sdiSuppliers && sdiSuppliers.size > 0)
      isDisabled = false;

    setDisabled(isDisabled);
  }, [watchFields]);

  useEffect(() => {
    if (tokenAvailable && strategyNumber > 0)
      callStrategiesAPI(
          applicationName,
          strategyNumber,
          sdfNumber,
          setStrategyInfo,
          setStrategicSuppliers,
          incrementAPIInProgress,
          decrementAPIInProgress,
          setStatusErrorMsg
      );
  }, [strategyNumber, applicationName, sdfNumber, tokenAvailable]);

  useEffect(() => {
    if (strategyInfo) {
      loadStrategyOwnerInfo(strategyInfo);
      validateAndSetAccess();
    }
  }, [strategyInfo, sdfInfo]);

  useEffect(() => {
    if (tokenAvailable && sdfNumber > 0)
      callSdfAPI(
          sdfNumber,
          setSdfUser,
          setSdfInfo,
          setSuppliersMap,
          incrementAPIInProgress,
          decrementAPIInProgress,
          setStatusErrorMsg
      );
  }, [sdfNumber, tokenAvailable]);

  useEffect(() => {
    if (strategyInfo && sdfInfo) {
      let value = sdfInfo.sdsConsidered ? "Yes" : "No";
      let flag = false;
      setStatusWarningMsg(null);
      setIsFormDirty(false);

      if (!sdfInfo.sdsConsidered && sdiSuppliers && sdiSuppliers.size > 0) {
        value = "Yes";
        flag = true;
      }
      if (
          sdfInfo.sdsConsidered &&
          (!sdiSuppliers || sdiSuppliers.size === 0) &&
          sdfInfo.vettedSuppliers.length === 0
      ) {
        value = "";
        flag = true;
      }

      if (flag) {
        let error = "Action Required.";
        let errorMore =
            "Supplier changes have been made in the strategy that impacts this form. Please Save this form now to sync data between strategy & SDF.";
        setStatusWarningMsg(error, errorMore);
        setIsFormDirty(true);
      }
      setSdsConsideredForEdit(value);
    }
  }, [strategyInfo, sdfInfo, sdiSuppliers]);

  function awayFunc(e) {
    e.returnValue = isFormTouched() ? "" : null;
  }

  useEffect(() => {
    if (isFormTouched()) window.addEventListener("beforeunload", awayFunc);
    else window.removeEventListener("beforeunload", awayFunc);
    return () => window.removeEventListener("beforeunload", awayFunc);
  }, [isDirty, isFormDirty]);

  usePrompt(!showModal && isFormTouched());

  return !strategyInfo && statusMessages?.error ? (
      <MessageBanner
          statusMessages={statusMessages}
          apiInProgressCount={apiInProgressCount}
      />
  ) : (
      info &&
      strategyInfo &&
      hasAccess &&
      (hasAccess === NO ? (
          <NoAccessEmployee userId={loggedInUser} />
      ) : (
          <main id="content" className="container-fluid pb-5 bg-gray">
            <Form onSubmit={handleSubmit(save)}>
              <div className="container-fluid pb-2 bg-gray position-sticky fixed-top">
                <Row className="mt-3">
                  <Col>
                    <h2 className="mt-2 float-left">{headerText}</h2>
                  </Col>
                  <Col>
                    <div className="mt-2 float-right">
                      <Button variant="secondary" onClick={cancelStrategy}>
                        Cancel
                      </Button>
                      <Button
                          title={
                            disabled
                                ? isDirty || sdfNumber === 0
                                    ? "Please complete all required fields."
                                    : "Please update vetted suppliers section or add comments to enable Save."
                                : ""
                          }
                          variant="primary"
                          type="submit"
                          disabled={disabled}
                      >
                        {saveOrSubmitButtonText}
                      </Button>
                    </div>
                  </Col>
                </Row>

                {sdfNumber > 0 && (
                    <h6>
                      Last updated on{" "}
                      {sdfInfo &&
                          dateFormat(new Date(sdfInfo.updatedOn), formatString)}{" "}
                      by {sdfUser ? sdfUser : sdfInfo?.updatedById}
                    </h6>
                )}
                <br />
                <MessageBanner
                    statusMessages={statusMessages}
                    apiInProgressCount={apiInProgressCount}
                />
              </div>

              <GetProspectingAndReasonCodes
                  commonProps={commonProps}
                  setReasonCodes={setReasonCodes}
                  setProspectingTool={setProspectingTool}
              />
              <GetSDIMasterList
                  commonProps={commonProps}
                  setSdiMasterList={setSdiMasterList}
              />
              <Row className="mt-3">
                <Col>
                  {applicationName === INDIRECT_STRATEGY_MANAGEMENT ? (
                      <SMIndirectStrategy
                          formType={formType}
                          strategyInfo={strategyInfo}
                          applicationName={applicationName}
                          commonProps={commonProps}
                      />
                  ) : (
                      <DMSMStrategy
                          formType={formType}
                          strategyInfo={strategyInfo}
                          strategyOwnerInfo={strategyOwnerInfo}
                          commonProps={commonProps}
                      />
                  )}
                  {strategyInfo && (
                      <>
                        <br />
                        {sdiMasterList && (
                            <Row>
                              <Col md="24">
                                <Suppliers
                                    commonProps={commonProps}
                                    preferredSuppliers={strategicSuppliers}
                                    sdiSuppliers={sdiSuppliers}
                                    setSdiSuppliersForParent={setSdiSuppliers}
                                    sdiMasterList={sdiMasterList}
                                />
                              </Col>
                            </Row>
                        )}
                        <br />

                        <Row>
                          <Col md="24">
                            <SDSConsidered
                                commonProps={commonProps}
                                reasonCodes={reasonCodes}
                                suppliersMap={suppliersMap}
                                prospectingTool={prospectingTool}
                                isDirty={isFormDirty}
                                sdfInfo={sdfInfo}
                                sdiSuppliers={sdiSuppliers}
                                initialSdsConsidered={
                                  sdsConsideredForEdit
                                      ? sdsConsideredForEdit
                                      : sdiSuppliers && sdiSuppliers.size > 0
                                          ? "Yes"
                                          : ""
                                }
                                sdiMasterList={sdiMasterList}
                            />
                          </Col>
                        </Row>
                      </>
                  )}
                </Col>
              </Row>
            </Form>
            <SMModal
                showModal={showModal}
                handleCancel={() => setShowModal(false)}
                handleYes={() => windowClose(history)}
                title="Changes Will Be Lost"
                msg_line1="Do you want to save your changes before leaving this screen?"
                msg_line2="If you don't save, your changes will be lost."
                cancelText="Cancel"
                yesText="Continue without Saving"
            />
          </main>
      ))
  );
}
